*{
  margin: 0;
  padding: 0;
}
.App {
  text-align: center;
  height: 100%;
  width: 100%;
}

.app-label{
  width: 50%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 2;
  background: white;
  pointer-events: none;
}

.map-container{
  position: 'absolute';
  height: 100%;
  width: 100%;
}

.mapTitle{
  position: absolute;
  background: white;
  border-radius: 5px;
  box-shadow: 5px 5px 8px #888888;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*transition: all 0.5s;*/
}
.mapBox {
  transition: all 0.25s;
}
.mapTitle p {
  margin: 10px;
}

.prevent-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.roundButton {
  background: white;
  border-radius: 5px;
  box-shadow: 5px 5px 8px #888888;
  border-radius:50px; 
  height:30px; 
  width:30px; 
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.avaSelected {
  -webkit-filter: drop-shadow(0px 0px 10px red);
  filter: drop-shadow(0px 0px 10px red);
}

.langSelected {
  background-color: blue;
}