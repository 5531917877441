html,
body {
  overscroll-behavior: contain;
}

input {
  font-size: large;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.menuBotons {
  padding: 5px;
  font-size: 100px;
}

.submenu {
    position: absolute;
    overflow: hidden;
    height: 100%;
  };

p {
  margin-bottom: 0px;
  margin-top: 0px;
}

.bottons {
  padding: 5px;
  padding-left: 10px;
  min-width: 70px;
  padding-right: 10px; 
  color: white;
  border-radius: 10px;
  font-weight: bolder;
  cursor: pointer;
  text-align: center;
  border: solid 3px white;
  display: flex;
  align-items:center;
  justify-content:center;
}

.listpoint {
  position: absolute;
  border: solid 2px red;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  font-weight: bolder;
  text-align:center;
  line-height: 30px;
  top:-5px;
  left:5px;
  cursor: pointer;
}

.titleDate {
  font-weight: bolder;
  color: #767676;
}

.table {
  table-layout: fixed;
  background-color: #BFBFBF;
  min-width: 200px;
}
.titlecalendar th {
  position: sticky;
  color:white;
  font-size: 18px;
  padding: 20px;
  text-align: center;
  background-color: #0400FF;
  font-weight: bolder;
  z-index: 100;
  top: 0;
  overflow: hidden;
}

.tableTd {
  position: relative;
  padding: 10px;
  height: 100px;
  vertical-align: top;
  background-color: white;
  transition: background-color 0.25s;
}

.outMonth {
  background-color: #E6E6E6;
}

.tableTd:hover {
  background-color: #E5E9FF;
}

.activity {
  background-color: #FFD47F;
  padding: 3px;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
  padding-left: 7px;
  cursor: pointer;
}

.activity2 {
  background-color: #FFD47F;
  padding: 3px;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
  padding-left: 7px;
  cursor: pointer;
}

.activityGreen {
  background-color: #BAEEBA;
}

.activityGreen2 {
  background-color: #BAEEBA;
}

.addActivity {
  position: absolute;
  right: 3px;
  top: 3px;
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 30px;
  color: white;
  line-height: 25px;
  background-color: green;
  border-radius: 25px;
  overflow: hidden;
  margin-top: 3px;
  font-weight: bolder;
  cursor: pointer;
}
/*.addActivity {
  position: absolute;
  right: 3px;
  top: 3px;
  color: green;
  cursor: pointer;
}*/

.addActivity2 {
  position: absolute;
  right: 3px;
  top: 3px;
  color: green;
  cursor: pointer;
}

.tileImg {
  width: 400px; 
  border: #767676 solid 2px;
  box-sizing: border-box;
}

@media (max-width:420px) { 
  .tileImg {
    width: 100%;
  }
}

@media (max-width:960px) { 
  .titlecalendar th {
    font-size: 14px;
    padding: 5px;
  }

  .tableTd {
    height: 20px;
  }

  .activity {
    white-space: nowrap;
    margin-top: 5px;
    height: 3px;
    font-size: 0px;
    background-color: #FFA900;
  } 

  .activityGreen {
    background-color: #00C900;
  }

  .addActivity {
    display: none;
  }
}
